import {get, merge} from 'lodash';

export const processIntergration = (rawData) => {
    const callbackParams = JSON.parse(get(rawData, 'callbackParams') || '{}')
    const requestParams = JSON.parse(get(rawData, 'requestParams') || '{}');
    const authParams = JSON.parse(get(rawData, 'authParams') || '{}');
    const params = merge({}, authParams, requestParams, callbackParams);
    return {
        rawData,
        id: get(rawData, 'id'),
        displayName: get(rawData, 'displayName'),
        enable: Boolean(get(rawData, 'enable')),
        subType: get(rawData, 'subType'),
        type: get(rawData, 'type'),
        callbackParams,
        requestParams,
        authParams,
        params,
        createdAt: get(rawData, 'createdAt'),
    };
};
