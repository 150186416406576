import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {get} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processBlockchain} from 'data/blockchain';
import {processThridPartyPaymentGateway} from 'data/thirdParty';
import {processIntergration} from 'data/intergration';

export const useAdminPaymentGatewayThridParty = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['admin/payment-channels-thrid-party'],
        request: async ([_key]) => {
            const {result} = await api().get(
                ['setting', 'platform-third-party'],
                {type: 'payment'},
            );
            return result;
        },
        defaultValue: [],
    });
};

export const useSystemThirdParty = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['admin/system-third-party'],
        request: async (_key) => {
            const {result} = await api().get(['setting', 'system-third-party']);
            return result;
        },
        defaultValue: [],
    });
};

export const useAdminPaymentGateway = (options = {}) => {
    const {} = options;
    const swr = useSWRTemplate({
        key: ['admin/payment-gateway'],
        request: async ([_]) => {
            const {result} = await api().get([
                'system',
                'gateway',
                'payment',
                'currency',
            ]);
            return result.map(processThridPartyPaymentGateway);
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminPaymentGatewayDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['admin/payment-gateway', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get([
                'system',
                'gateway',
                'payment',
                'currency',
                _id,
            ]);
            return processThridPartyPaymentGateway(result);
        },
        defaultValue: {},
    });
    return swr;
};

export const newPaymentGateway_ = async (id, data) => {
    const {result} = await api().post(
        ['system', 'gateway', 'payment', 'currency', , id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const updatePaymentGateway_ = async (id, data) => {
    const {result} = await api().put(
        ['system', 'gateway', 'payment', 'currency', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useThirdPartyRules = () => {
    const swr = useSWRTemplate({
        key: ['third-party-rules'],
        request: async ([_]) => {
            // const query = {
            //     type: _type,
            // };
            const {result} = await api().get(
                ['platform', 'gateway', 'payment', 'currency'],
                // cleanObjectNilValue(query),
            );
            return result.map((item) => ({
                rawData: item,
                displayName: get(item, 'displayName'),
                id: get(item, 'id'),
                authParams: JSON.parse(get(item, 'authParams') || '{}'),
                callbackParams: JSON.parse(get(item, 'callbackParams') || '{}'),
                requestParams: JSON.parse(get(item, 'requestParams') || '{}'),
            }));
        },
        defaultValue: [],
    });
    return swr;
};

export const useBlockchainConfig = (options = {}) => {
    const {} = options;
    const swr = useSWRTemplate({
        key: ['admin/blockchainConfig'],
        request: async ([_]) => {
            const {result} = await api().get([
                'system',
                'gateway',
                'payment',
                'crypto',
            ]);
            return result.map(processBlockchain);
        },
        defaultValue: [],
    });
    return swr;
};

export const updateBlockChainConfig_ = async (
    id,
    {enable, platformWalletAddress},
) => {
    const {result} = await api().put(
        ['system', 'gateway', 'payment', 'crypto', id],
        cleanObjectNilValue({
            enable,
            platformWalletAddress,
        }),
    );
    return result;
};

export const usePlatformAvailableIntergations = (options = {}) => {
    const {type} = options;
    const swr = useSWRTemplate({
        key: ['platformAvailableIntergations', type],
        request: async ([_, _type]) => {
            const query = {
                type: _type,
            };
            const {result} = await api().get(
                ['platform', 'plugins'],
                cleanObjectNilValue(query),
            );
            return result.map(processIntergration);
        },
        defaultValue: [],
    });
    return swr;
};

export const useSystemAddedIntergations = (options = {}) => {
    const {type} = options;
    const swr = useSWRTemplate({
        key: ['systemAddedIntergrations', type],
        request: async ([_, _type]) => {
            const query = {
                type: _type,
            };
            const {result} = await api().get(
                ['platform', 'intergrations'],
                cleanObjectNilValue(query),
            );
            return result.map(processIntergration);
        },
        defaultValue: [],
    });
    return swr;
};

export const addIntergrationToSystem_ = async (payload) => {
    const {result} = await api().post(
        ['platform', 'intergrations'],
        cleanObjectNilValue(payload),
    );
    return result;
};

export const editIntergration_ = async (id, payload) => {
    const {result} = await api().put(
        ['platform', 'intergrations', id],
        payload,
    );
    return result;
};

export const requestKycIntergrationVerification_ = async (user) => {
    const {result} = await api().post(['intergrations', 'kyc'], {user});
    return result;
};

export const useKYCIntergrationResults = (options = {}) => {
    const {user} = options;
    const swr = useSWRTemplate({
        key: user ? ['intergrationsKycResult', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(['intergrations', 'kyc'], {
                user: _user,
            });
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const requestAMLIntergrationVerification_ = async (user) => {
    const {result} = await api().post(['intergrations', 'aml'], {user});
    return result;
};

export const useAMLIntergrationResults = (options = {}) => {
    const {user} = options;
    const swr = useSWRTemplate({
        key: user ? ['intergrationsAMLResult', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(['intergrations', 'aml'], {
                user: _user,
            });
            return result;
        },
        defaultValue: [],
    });
    return swr;
};
