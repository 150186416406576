import {useSWRPaginationTemplate, useSWRTemplate} from '../dataHooksTemplate';
import {api} from './common';
import {useAuth} from '../useAuth';
import {sleep_} from '@genx/july/lib/commonjs/lang';
import {processStockAccountList} from '../../data/stock';
import {cleanObjectNilValue} from '../../utils/clean';
import {get, reverse, isNil} from 'lodash';
import {
    currencyFormatorDetail,
    currencyFormatorOfficial,
    currencyFormatorWithDecimal,
    formatDateInTimezone,
    formatMonthDataAndYear,
    getLastAndLastDayOfMonth,
} from '../../utils/formator';
import Excel from 'exceljs';
import {processUserProfile} from '../../data/userProfile';
import {processForexAccountList} from '../../data/forex';


export const useStackAccount = (options = {}) => {
    const {request = true, userId, status} = options;
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: request && id ? ['forexAccountDetail', id, userId, status] : null,
        request: async ([_, _id, _userId, _status]) => {
            const {result} = await api().get(['stock', 'account'],
                cleanObjectNilValue({
                    userId: _userId,
                    status: _status,
                }));
            return result.map(processStockAccountList);
        },
        defaultValue: [],
    });
    return {
        ...swr,
        stockAccount: swr.data[0],
    };
};

export const useStackAccountDetail = (options = {}) => {
    const {id, request = true} = options;
    const swr = useSWRTemplate({
        key: id && request ? ['stackAccountDetail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['stack', 'account', _id]);
            return processStockAccountList(result);
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};


export const useAdminStockProduct = (options = {}) => {
    const {exchangeCode, name, status} = options;
    const swr = useSWRPaginationTemplate({
        key: ['AdminStockProduct', exchangeCode, name, status],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _exchangeCode, _name, _status] = _key;
            const {result} = await api().get(
                ['stock', 'product'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    exchangeCode: _exchangeCode,
                    name: _name,
                    status: _status,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: result?.items,
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAdminStackAccount = (options = {}) => {
    const {keyword, hasDeposit, status} = options;
    const swr = useSWRPaginationTemplate({
        key: ['adminStackAccount', keyword, status, hasDeposit],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _keyword, _status] = _key;
            const {result} = await api().get(
                ['stock', 'account'], cleanObjectNilValue({
                    hasDeposit: !isNil(hasDeposit) ? Boolean(Number(hasDeposit)) : null,
                    keyword: _keyword,
                    status: _status,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: result?.items.map(processStockAccountList),
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useUserStockAccountList = (options = {}) => {
    const {user,status} = options;
    const swr = useSWRTemplate({
        key: user ? [ 'useUserStockAccountList',user, status] : null,
        request: async ([_, _user, _status,]) => {
            const {result} = await api().get(
                ['stock', 'account',],
                cleanObjectNilValue({
                    userId: _user,
                    status: _status,
                }),
            );
            return result.map(processStockAccountList);
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAdminOpenPositions = (options = {}) => {
    const {code} = options;
    const swr = useSWRPaginationTemplate({
        key: ['AdminOpenPositions', code],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _code] = _key;
            const {result} = await api().get(
                ['stock', 'position-statics'], cleanObjectNilValue({
                    IressCode: _code ? _code : null,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items'),
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};


export const useClientOpenPositions = (options = {}) => {
    const {keyword, code, user,request = true,} = options;
    const swr = useSWRPaginationTemplate({
        key: request ? ['ClientOpenPositions', keyword, code, user] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _keyword, _code, _user] = _key;
            const {result} = await api().get(
                ['stock', 'position'], cleanObjectNilValue({
                    keyword: _keyword,
                    IressCode: _code ? _code : null,
                    user: _user,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => ({
                        ...item,
                        baseCurrency: get(item, ':account.baseCurrency'),
                        clientDetail: processUserProfile(get(item, ':account.:user')),
                    })),
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClientPositionDetails = (options = {}) => {
    const {code,request = true,} = options;
    const swr = useSWRTemplate({
        key: code && request ? ['ClientPositionDetails', code] : null,
        request: async ([_,_code]) => {
            const {result} = await api().get(
                ['stock', 'position',_code],
            );
            return (result || []).map((item) => ({
                ...item,
                baseCurrency: get(item, ':account.baseCurrency'),
                clientDetail: processUserProfile(get(item, ':account.:user')),
            }));
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useLiveSubscriptions = (options = {}) => {
    const {} = options;
    const swr = useSWRPaginationTemplate({
        key: ['LiveSubscriptions'],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_] = _key;
            // const {result} = await api().get(
            //     ['stock', 'account'],
            // );
            await sleep_(1000);
            const list = [
                {
                    id: 1,
                    symbol: 'Test',
                    code: '09001',
                    currentPrice: 52.5,
                    changePercent: 0.05,
                    todayChange: 2.5,
                    collectedYesterday: 50,
                    isBuy: true,
                    sector: 'Electronic',
                },
                {
                    id: 1,
                    symbol: 'Test2',
                    code: '09002',
                    currentPrice: 19,
                    changePercent: -0.05,
                    todayChange: 1,
                    collectedYesterday: 20,
                    isBuy: false,
                    sector: 'Metal',
                },
            ];

            return {
                result: {
                    totalItems: list.length,
                    items: list,
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useStockProducts = (options = {}) => {
    const {exchangeCode, name} = options;
    const swr = useSWRPaginationTemplate({
        key: ['stockProducts', exchangeCode, name],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _exchangeCode, _name] = _key;
            const {result} = await api().get(
                ['stock', 'product'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    exchangeCode: _exchangeCode,
                    name: _name,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: result?.items,
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useExchangeList = (options = {}) => {
    const {request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['forexAccountDetail'] : null,
        request: async ([_]) => {
            const {result} = await api().get(['exchange-list']);
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: swr?.data.map((item) => ({
            ...item,
            label: item?.exchangeCode,
            value: item?.exchangeCode,
        })),
    };
};

export const useStackPositionTop = (options = {}) => {
    const {user = null} = options;
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: id ? ['stackPositionTop', id, user] : null,
        request: async ([_, _id, _user]) => {
            const {result} = await api().get(
                ['stock', 'position-top'],
                cleanObjectNilValue({
                    user: _user,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useStockBalanceTrends = (options = {}) => {
    const {yearMonth, user = null} = options;
    const swr = useSWRTemplate({
        key: ['StockBalanceTrends', yearMonth, user],
        request: async ([_, _yearMonth, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                user: _user,
            };
            const {result} = await api().get(
                ['stock', 'balance-chart'],
                cleanObjectNilValue(query),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useStockStatements = (options = {}) => {
    const {user = null} = options;
    const swr = useSWRTemplate({
        key: ['StockStatements', user],
        request: async ([_, _user]) => {
            const query = {
                user: _user,
            };
            const {result} = await api().get(['stock', 'statement'],
                cleanObjectNilValue(query));
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const downloadStockStatements = async (month, userId) => {
    const date = getLastAndLastDayOfMonth(month);
    const {result} = await api().get(['transaction'], cleanObjectNilValue({
        startDate: date?.startDate,
        endDate: date?.endDate,
        user: userId,
        source: 'Stock',
    }));
    const {result: positionResult} = await api().get(['stock', 'position'], cleanObjectNilValue({
        // startDate: date?.startDate,
        endDate: date?.endDate,
        user: userId,
    }));

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Statements');
    sheet.columns = [
        {header: 'Last updated', key: 'date'},
        {header: 'Ticket', key: 'ticket'},
        {header: 'Type', key: 'type'},
        {header: 'From account', key: 'fromAccount'},
        {header: 'From amount', key: 'fromAmount'},
        {header: 'To account', key: 'toAccount'},
        {header: 'To amount', key: 'toAmount'},
        {header: 'Comment', key: 'comment'},
    ];
    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    const date = get(grant, 'mtTicket')
                        ? get(grant, 'statusCompletedTimestamp')
                        : get(grant, 'updatedAt') ||
                        get(grant, 'createdAt') ||
                        get(grant, 'statusPendingTimestamp');
                    const moscowTime = date
                        ? formatDateInTimezone(date, 'UTC')
                        : '';
                    row.push(moscowTime);
                    break;
                case 'ticket':
                    row.push(grant.ticket);
                    break;
                case 'type':
                    row.push(grant.type);
                    break;
                case 'fromAccount':
                    row.push(
                        get(grant, 'fromAccount'),
                    );
                    break;
                case 'fromAmount':
                    row.push(
                        get(grant, 'from_amount'),
                    );
                    break;
                case 'toAccount':
                    row.push(
                        get(grant, 'toAccount'),
                    );
                    break;
                case 'toAmount':
                    row.push(
                        get(grant, 'to_amount'),
                    );
                    break;
                case 'comment':
                    row.push(grant.comment);
                    break;
            }
        }
        sheet.addRow(row);
    }

    const positionWorkbook = new Excel.Workbook();
    const positionSheet = positionWorkbook.addWorksheet('Positions');
    positionSheet.columns = [
        {header: 'Code', key: 'IressCode'},
        {header: 'Exchange', key: 'exchange'},
        {header: 'Security name', key: 'securityName'},
        {header: 'Quantity', key: 'tradeQuantity'},
        {header: 'Position price', key: 'tradePrice'},
        {header: 'Market price', key: 'marketPrice'},
        {header: 'Market value', key: 'notionalValue'},
        {header: 'Unrealised P&L', key: 'unRealisedPL'},
        // {header: 'Run date', key: 'runDate'},
    ];
    for (const grant of positionResult) {
        const row = [];
        for (const col of positionSheet.columns) {
            switch (col.key) {
                case 'IressCode':
                    row.push(get(grant, 'IressCode'));
                    break;
                case 'exchange':
                    row.push(get(grant, ':product.exchangeCode'));
                    break;
                case 'securityName':
                    row.push(get(grant, ':product.securityDescription'));
                    break;
                case 'tradeQuantity':
                    row.push(currencyFormatorOfficial(get(grant, 'tradeQuantity')));
                    break;
                case 'tradePrice':
                    row.push(currencyFormatorDetail(get(grant, 'tradePrice'), get(grant, 'ledgerCurrency'), 4, true));
                    break;
                case 'marketPrice':
                    row.push(currencyFormatorDetail(get(grant, 'marketPrice'), get(grant, 'ledgerCurrency'), 4, true));
                    break;
                case 'notionalValue':
                    row.push(currencyFormatorDetail(get(grant, 'notionalValue'), get(grant, 'ledgerCurrency'), 4, true));
                    break;
                case 'unRealisedPL':
                    row.push(currencyFormatorDetail(`${Number(get(grant, 'unRealisedPL')) > 0 ? '+' : ''}${get(grant, 'unRealisedPL')}`, get(grant, 'ledgerCurrency'), 4, true));
                    break;
                // case 'runDate':
                //     row.push(get(grant, 'runDate'));
                //     break;
            }
        }
        positionSheet.addRow(row);
    }


    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = month + '-Statements.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);

    const positionBuffer = await positionWorkbook.xlsx.writeBuffer();
    let positionA = window.document.createElement('a');
    positionA.href = URL.createObjectURL(
        new Blob([positionBuffer], {type: 'application/vnd.openxmlformats'}),
    );
    positionA.download = month + '-Positions.xlsx';
    window.document.body.appendChild(positionA);
    positionA.click();
    window.document.body.removeChild(positionA);
};

export const useDashboardStackTrends = (options = {}) => {
    const {yearMonth, user = null, source = null} = options;
    const swr = useSWRTemplate({
        key: ['dashboardStackTrends', yearMonth, user, source],
        request: async ([_, _yearMonth, _user, _source]) => {
            const query = {
                yearMonth: _yearMonth,
                user: _user,
                source: _source,
            };
            const {result} = await api().get(
                ['stock', 'total-balance-chart'],
                cleanObjectNilValue(query),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: reverse(swr.data),
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useStockTradesTrends = (options = {}) => {
    const {yearMonth, type, user = null, request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['stockTradesTrends', yearMonth, type, user] : null,
        request: async ([_, _yearMonth, _type, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                user: _user,
            };
            const {result} = await api().get(
                ['stock', `${_type}-chart`],
                cleanObjectNilValue(query),
            );
            return {
                ...result,
                data: get(result, 'data').map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: reverse(swr.data),
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const addProduct_ = async (data) => {
    const {result} = await api().post(
        ['stock', 'module-manage'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const addStockAccount_ = async (data) => {
    const res = await api().post(['stock', 'account'], cleanObjectNilValue(data));
    return res;
};

export const editStockProductStatus_ = async (id, data) => {
    const {result} = await api().put(
        ['stock', 'product', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const stockAdjustment_ = async (data) => {
    const {result} = await api().post(
        ['stock', 'adjustment'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const suspendStockAccount_ = async (id, data) => {
    const res = await api().put(['stock', 'account', id], data);
    return res;
};

export const stockSendPassword_ = async (data) => {
    const {result} = await api().post(
        ['stock', 'send-password'],
        cleanObjectNilValue(data),
    );
    return result;
};