import React, {useEffect} from 'react';
import {
    Center,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
} from '@chakra-ui/react';
import {IMaskMixin} from 'react-imask';
import numeral from 'numeral';

const MaskedStyledInput = IMaskMixin(({inputRef, leftIcon, size, ...props}) =>
    props?.showLeftElement ? (
        <InputGroup size={size || 'md'}>
            <InputLeftElement pointerEvents="none" h={'100%'}>
                <Text pt={'1px'}>{props.currency}</Text>
            </InputLeftElement>
            <Input
                {...props}
                ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
            />
        </InputGroup>
    ) : leftIcon ? (
        <InputGroup size={size||'md'}>
            <InputLeftElement pointerEvents="none" h={'100%'}>
                {leftIcon}
            </InputLeftElement>
            <Input
                {...props}
                ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
            />
        </InputGroup>
    ) : (
        <Input
            {...props}
            ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
        />
    ),
);

export const EnglishInput = (props) => {
    const {placeholder, value, onChange, leftIcon, ...other} = props;
    return (
        <MaskedStyledInput
            mask={/^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/}
            value={value || ''}
            onAccept={(value, mask) => {
                // console.log(value, mask);
                onChange({target: {value}});
            }}
            placeholder={placeholder}
            leftIcon={leftIcon}
            {...other}
        />
    );
};

const DebitCardMaskedStyledInput = IMaskMixin(({inputRef, leftIcon, size, ...props}) =>
    props?.showLeftElement ? (
        <InputGroup size={size || 'md'}>
            <InputLeftElement pointerEvents="none" h={'100%'}>
                <Text pt={'1px'}>{props.currency}</Text>
            </InputLeftElement>
            <Input
                {...props}
                ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
            />
        </InputGroup>
    ) : leftIcon ? (
        <InputGroup size={size||'sm'}>
            <InputLeftElement pointerEvents="none" h={'100%'}>
                {leftIcon}
            </InputLeftElement>
            <Input
                {...props}
                ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
                size={size||'sm'}
            />
        </InputGroup>
    ) : (
        <Input
            {...props}
            ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
            size={size||'sm'}
        />
    ),
);

export const DebitCardEnglishInput = (props) => {
    const {placeholder, value, onChange, leftIcon, ...other} = props;
    return (
        <DebitCardMaskedStyledInput
            mask={/^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/}
            value={value || ''}
            onAccept={(value, mask) => {
                // console.log(value, mask);
                onChange({target: {value}});
            }}
            placeholder={placeholder}
            leftIcon={leftIcon}
            {...other}
        />
    );
};
export const SaleCodeInput = (props) => {
    const {placeholder, value, onChange, ...other} = props;
    return (
        <MaskedStyledInput
            mask={/^[a-zA-Z][a-zA-Z0-9]{0,5}$/}
            value={value || ''}
            onAccept={(value, mask) => {
                // console.log(value, mask);
                onChange({target: {value}});
            }}
            placeholder={placeholder}
            {...other}
        />
    );
};
export const BsbInput = (props) => {
    const {placeholder, value, onChange, ...other} = props;
    return (
        <MaskedStyledInput
            mask={'000-000'}
            value={value || ''}
            onAccept={(value, mask) => {
                console.log(value, mask);
                onChange({target: {value}});
            }}
            placeholder={placeholder}
            {...other}
        />
    );
};

export const CurrencyInput = (props) => {
    const {placeholder, value, onChange, currency, ...other} = props;
    return (
        <MaskedStyledInput
            mask={Number}
            radix="."
            thousandsSeparator=","
            value={`${value}` || ''}
            unmask={true}
            placeholder={placeholder}
            onAccept={(value, mask) => {
                onChange({target: {value}});
            }}
            currency={currency}
            showLeftElement={false}
            {...other}
        />
    );
};

// export const CurrencyInput = (props) => {
//     const {
//         prefix = '',
//         sufix = '',
//         value,
//         onChangeText,
//         onBlur,
//         ...other
//     } = props;
//     const [displayValue, setDisplayValue] = useState(
//         value !== '' ? currencyFormator(trim(value), prefix) : '',
//     );
//     const [isFoucs, setIsFocus] = useState(false);
//     const grayColor = useColorModeValueKey('gray');
//     useEffect(() => {
//         if (value) {
//             // console.log('has value')
//             const newV = value !== '' ? currencyFormator(trim(value)) : '';
//             const newD = `${prefix || ''}${newV}${sufix || ''}`;
//             if (displayValue !== newD) {
//                 setDisplayValue(newD);
//             }
//         } else if (value === '' && displayValue !== '') {
//             // console.log('no value')
//             setDisplayValue('');
//         }
//     }, [value, prefix]);
//     return (
//         <Input
//             onFocus={() => setIsFocus(true)}
//             onBlur={() => {
//                 const newV = value !== '' ? currencyFormator(trim(value)) : '';
//                 if (newV === '') {
//                     setDisplayValue('');
//                 } else {
//                     setDisplayValue(`${prefix || ''}${newV}${sufix || ''}`);
//                 }
//                 if (newV) {
//                     onChangeText(numeral(newV).value());
//                 }
//                 if (onBlur) onBlur(numeral(newV).value());
//                 setIsFocus(false);
//             }}
//             keyboardType="decimal-pad"
//             value={isFoucs ? value : displayValue}
//             onChangeText={onChangeText}
//             {...other}
//             _focus={{
//                 placeholderTextColor: grayColor,
//             }}
//         />
//     );
// };

export const PercentageInput = (props) => {
    const {value, onChange, fixed = 4, ...other} = props;
    const [displayValue, setDisplayValue] = React.useState(`${value * 100}`);
    useEffect(() => {
        setDisplayValue(`${value * 100}`);
    }, [value]);
    return (
        <InputGroup>
            <Input
                value={displayValue}
                onChange={(e) => setDisplayValue(e.target.value)}
                onBlur={() => {
                    const v = numeral(displayValue / 100)
                        .value()
                        .toFixed(fixed);
                    onChange(v);
                }}
                {...other}
            />
            <InputRightElement h={8}>
                <Text textAlign={'center'}>%</Text>
            </InputRightElement>
        </InputGroup>
    );
};
