import {get,split} from 'lodash';

export const processSetting = (rawData) => {
    return {
        id: get(rawData, 'id'),
        isMobileVerified: Boolean(get(rawData, 'isMobileVerified')),
        isEmailVerified: Boolean(get(rawData, 'isEmailVerified')),
        enableEmailFact: Boolean(get(rawData, 'enableEmailFact')),
        enableSmsFact: Boolean(get(rawData, 'enableSmsFact')),
        enableOtpFact: Boolean(get(rawData, 'enableOtpFact')),
        disableMobile2FA: Boolean(get(rawData, 'disableMobile2FA')),
        smsNotification: Boolean(get(rawData, 'smsNotification')),
        loginTwoFactor: Boolean(get(rawData, 'loginTwoFactor')),
        emailNotification: Boolean(get(rawData, 'emailNotification')),
        stockColor: get(rawData, 'stockColor'),
        productAccess: get(rawData, 'productAccess'),
        isStock:
            get(rawData, 'productAccess') &&
            split(get(rawData, 'productAccess'), ',').includes('stock'),
        locale: get(rawData, 'locale'),
        timezone: get(rawData, 'timezone'),
        twoFactor: Boolean(get(rawData, 'twoFactor')),
        allowDevelop: Boolean(get(rawData, 'allowDevelop')),
        enableCryptoWithdrawal: Boolean(get(rawData, 'enableCryptoWithdrawal')),
        preferredPlatformCurrency:
            get(rawData, 'preferredPlatformCurrency') || 'USD',
        requiredUpdatePassword: Boolean(get(rawData, 'requiredUpdatePassword')),
        requiredUpdateSms2FA: Boolean(get(rawData, 'requiredUpdateSMS2FA')),
        requiredUpdateOtp2FA: Boolean(get(rawData, 'requiredUpdateOtp2FA')),
        enableCCSubAccount: Boolean(get(rawData, 'enableCCSubAccount')),
        enableExchangeWithdrawal: Boolean(
            get(rawData, 'enableExchangeWithdrawal'),
        ),
        enableExLink: Boolean(get(rawData, 'enableExLink')),
        enablePayGo: Boolean(get(rawData, 'enablePayGo')),
        enableWorldpay3DS: Boolean(get(rawData, 'enableWorldpay3DS')),
        enableDemoAccount: Boolean(get(rawData, 'enableDemoAccount')),
        antiPhishingCode: get(rawData, 'antiPhishingCode'),
        last2faDate: get(rawData, 'last2faDate'),
        enableDebit: get(rawData, 'enableDebitCard')
    };
};
