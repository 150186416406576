import create from 'zustand';

export const useApplyDebitCardStore = create((set, get) => ({
    values: {},
    setValues: (value) => set(() => ({values: value})),
    overrideValues: (value) =>
        set(() => ({values: {...get().values, ...value}})),
    clear: () =>
        set(() => ({
            hasEdit: false,
        })),
    hasEdit: false,
    setHasEdit: (value) => set(() => ({hasEdit: value})),
}));
