import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {find, isNil, keyBy} from 'lodash';
import {useColorModeValueKey} from 'hooks/useColors';
import {
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement as ChakraInputLeftElement,
    Spinner,
    Stack,
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    PopoverAnchor,
    Box,
    Center,
    Text,
    Button,
    useColorModeValue,
    Checkbox,
    Portal,
} from '@chakra-ui/react';
import {ChevronDownIcon, CloseIcon} from '@chakra-ui/icons';
import {useMeasure} from 'react-use';

const MutiSelector = (props) => {
    const {
        value = [],
        onChangeValue,
        placeholder,
        options,
        isLoading,
        flex = 1,
        customRenderItem,
        // customRenderInputRightElement,
        isDisabled,
        InputLeftElement,
        allowNoMatch = false,
        allowClear = true,
        hasAll = false,
        ...other
    } = props;
    const {t} = useTranslation('app');
    const grayColor = useColorModeValueKey('gray');
    const textColor = useColorModeValueKey('text');
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(false);
    const [inputLayoutRef, {width}] = useMeasure();

    // const matchedItem = find(options, {value});
    const kvPair = keyBy(options, 'value');
    const combinedString = hasAll
        ? (value || []).includes('all')
            ? t('all')
            : (value || []).map((item) => kvPair[item]?.label).join(', ')
        : (value || []).map((item) => kvPair[item]?.label).join(', ');

    const showLeftElement = InputLeftElement;

    const onClick = (e, isSelected, option) => {
        e.stopPropagation();
        console.log(e, isSelected, option.value);
        if (isSelected) {
            onChangeValue(
                value.filter((item) => item !== option.value),
                option,
            );
        } else {
            onChangeValue([...value, option.value], option);
        }
    };
    return (
        <Stack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isLazy
                closeOnBlur={true}
                autoFocus={false}
            >
                <PopoverAnchor>
                    <InputGroup size={'sm'} ref={inputLayoutRef}>
                        {showLeftElement && (
                            <ChakraInputLeftElement>
                                <HStack spacing={1} alignItems={'center'}>
                                    {InputLeftElement}
                                </HStack>
                            </ChakraInputLeftElement>
                        )}
                        <Input
                            value={combinedString}
                            ref={inputRef}
                            // isReadOnly
                            style={{
                                caretColor: 'transparent',
                            }}
                            variant={'outline'}
                            placeholder={placeholder || t('select')}
                            _placeholder={{
                                color: grayColor,
                            }}
                            flex={1}
                            noOfLines={1}
                            isDisabled={isDisabled}
                            onClick={() => setIsOpen((c) => !c)}
                            cursor={'pointer'}
                            {...other}
                        />
                        <InputRightElement width={'auto'} mr={1}>
                            <HStack
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={1}
                            >
                                {/* {customRenderInputRightElement
                                    ? customRenderInputRightElement(matchedItem)
                                    : null} */}
                                {value.length > 0 && allowClear && (
                                    <IconButton
                                        variant={'ghost'}
                                        isDisabled={isDisabled}
                                        onClick={() => onChangeValue([])}
                                        size="xs"
                                        icon={
                                            <CloseIcon
                                                boxSize={'10px'}
                                                color={grayColor}
                                            />
                                        }
                                    />
                                )}
                                {isLoading ? (
                                    <Spinner
                                        size="sm"
                                        color={useColorModeValueKey('primary')}
                                    />
                                ) : (
                                    <IconButton
                                        isDisabled={isDisabled}
                                        onClick={() => setIsOpen((c) => !c)}
                                        size="xs"
                                        variant={'ghost'}
                                        icon={
                                            <ChevronDownIcon
                                                fontSize={'20px'}
                                            />
                                        }
                                    />
                                )}
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                </PopoverAnchor>
                <PopoverContent
                    width={width}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody
                        px={0}
                        py={0}
                        bg={useColorModeValueKey('brighterBg')}
                        transition={'all 200ms linear'}
                    >
                        <Stack maxH={'300px'} overflow={'scroll'} spacing={0}>
                            {options.length > 0 ? (
                                options.map((option) => {
                                    const isSelected = value?.includes(
                                        option.value,
                                    );
                                    return (
                                        <Stack
                                            cursor={'pointer'}
                                            onClick={(e) =>
                                                option.disabled?null:onClick(
                                                    e,
                                                    isSelected,
                                                    option,
                                                )
                                            }
                                            key={option.value}
                                            px={3}
                                            py={2}
                                            _hover={{
                                                bg: useColorModeValueKey(
                                                    'menuHover',
                                                ),
                                                _active: {
                                                    bg: useColorModeValueKey(
                                                        'menuActive',
                                                    ),
                                                },
                                            }}
                                            disabled={option.disabled}
                                        >
                                            <Stack key={option.value}>
                                                {customRenderItem ? (
                                                    customRenderItem(
                                                        option,
                                                        isSelected,
                                                    )
                                                ) : (
                                                    <HStack
                                                        alignItems={'center'}
                                                    >
                                                        <Checkbox
                                                            isChecked={
                                                                isSelected
                                                            }
                                                            colorScheme="light.primary"
                                                            isDisabled={
                                                                option.disabled
                                                            }
                                                            pointerEvents={
                                                                'none'
                                                            }
                                                        />
                                                        <Text
                                                            fontSize={'sm'}
                                                            color={
                                                                option.disabled
                                                                    ? grayColor
                                                                    : undefined
                                                            }
                                                        >
                                                            {option.label}
                                                        </Text>
                                                    </HStack>
                                                )}
                                            </Stack>
                                        </Stack>
                                    );
                                })
                            ) : (
                                <Center px={3} py={2}>
                                    <Text color={grayColor}>{t('noData')}</Text>
                                </Center>
                            )}
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Stack>
    );
};

export default MutiSelector;
