import { Box, Stack } from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import { find, get, last, trimEnd } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { paths } from 'routes/path';
import { useProfile } from 'hooks/datahook/auth';
import { useGetApplyDetail } from 'hooks/datahook/debit';
import { processOriginalData } from 'data/debit'
import { useApplyDebitCardStore } from 'stores/applyDebitCardStore';
import { useKYCIntergrationResults } from 'hooks/datahook/adminIntergrations';

const DebitCardClientLayout = () => {

    const { t } = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const navigate = useNavigate();

    const state = useApplyDebitCardStore((state) => state);
    const { data: profile, isLoading: profileLoading } = useProfile();
    const { data: detailData, isLoading: detailLoading, mutate } = useGetApplyDetail(profile.id);
    const { data: kycIntegrationResults, isLoading: isLoadingKycResults } = useKYCIntergrationResults({ user: profile.id });
    const cardOriginalInfo = processOriginalData({ ...profile, ...kycIntegrationResults });

    useEffect(() => {
        if (Object.keys(detailData).length === 0 || detailData?.step < 2) {
            state.overrideValues(cardOriginalInfo);
        } else {
            state.overrideValues(detailData);
        }
    }, [profileLoading, detailLoading, isLoadingKycResults]);

    const tabs = [
        ...(detailData?.status === 'Activated') ? [
            {
                label: t('debitCard'),
                value: 'debitCard',
                path: paths.debitCard,
            }
        ] : [],
        ...(detailData?.status !== 'Activated') ? [
            {
                label: t('introduction'),
                value: 'introduction',
                path: paths.introduction,
                disabled: false
            },
            {
                label: t('applicationInformation'),
                value: 'applicationInformation',
                path: paths.applicationInformation,
                disabled: get(detailData, 'step') < 1 || Object.keys(detailData).length === 0
            },
            {
                label: t('reviewForSubmission'),
                value: 'reviewForSubmission',
                path: paths.reviewForSubmission,
                disabled: get(detailData, 'step') < 2 || Object.keys(detailData).length === 0
            },
            {
                label: t('selfieWithCard'),
                value: 'selfieWithCard',
                path: paths.selfieWithCard,
                disabled: get(detailData, 'step') < 5 || Object.keys(detailData).length === 0
            },
            {
                label: t('finalSubmission'),
                value: 'finalSubmission',
                path: paths.finalSubmission,
                disabled: get(detailData, 'step') < 5 || Object.keys(detailData).length === 0
            },
        ] : [],

        ...(detailData?.status === 'Activated') ? [
            {
                label: t('applicationDetails'),
                value: 'applicationDetails',
                path: paths.applicationDetails,
            },
            {
                label: t('feeAndTopups'),
                value: 'feeAndTopups',
                path: paths.feeAndTopups,
            }
        ] : [],
    ];
    const value =
        find(
            tabs,
            (o) =>
                trimEnd(matchPath, '/') === o.path ||
                trimEnd(matchPath, '/').includes(o.path),
        )?.value || tabs[0]?.value;

    return (
        <Stack spacing={4} width="100%" justifyContent={'center'} alignItems='center'>
            <Stack width={detailData?.status === 'Activated' ? '100%' : '1130px'} spacing={4}>
                <CustomTab
                    tabs={tabs}
                    tabOnly
                    value={value}
                    onChange={(v) => {
                        navigate(find(tabs, (o) => o.value === v)?.path);
                    }}
                />
                <Box width={'100%'}>
                    <Outlet />
                </Box>
            </Stack>
        </Stack>
    );
};

export default DebitCardClientLayout;