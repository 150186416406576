import { get, last } from 'lodash';
import { currencyFormatorWithDecimal } from 'utils/formator';
export const processCardType = (rawData, index, array) => {
    let currentCapital = '';
    if (index === 0) {
        currentCapital = 'Under USD ' + Math.round(parseInt(rawData.capital) / 1000) + 'k';
    } else if (index === array.length - 1) {
        currentCapital = 'USD ' + Math.round(parseInt(rawData.capital) / 1000) + 'k plus';
    } else {
        currentCapital = 'USD ' + Math.round(parseInt(array[index - 1].capital) / 1000) + 'k to USD ' + Math.round(parseInt(rawData.capital) / 1000) + 'k';
    }
    return {
        isEligible: rawData.canApply,
        type: rawData.code,
        name: rawData.name,
        cardType: rawData.name,
        last30Days: currentCapital,
        clientType: rawData.code,
        applicationFee: Number(rawData.fee) === 0 ? 'USD ' + parseInt(rawData.fee) + '(free)' : 'USD ' + parseInt(rawData.fee),
        topupFeePercent: Number(rawData.topupFeePercent) * 100 + '%',
        topUpAutoApproval: 'Within USD ' + Math.round(parseInt(rawData.topupLimit) / 1000) + 'k/day'
    };
};
export const processOriginalData = (profile) => {
    return {
        email: get(profile, 'email'),
        cardholder: get(profile, 'personDetail.firstname') + ' ' + get(profile, 'personDetail.lastname'),
        gender: get(profile, 'gender') ? get(profile, 'gender') === 'M' ? 'Male' : 'Female' : '',
        isdCode: get(profile, 'personDetail.phoneCode') ? get(profile, 'personDetail.phoneCode')[0] === '+' ? get(profile, 'personDetail.phoneCode').slice(1) : get(profile, 'personDetail.phoneCode') : '',
        telephone: get(profile, 'personDetail.phoneNo'),
        title: get(profile, 'rawData[:person].title'),
        occupation: get(profile, 'rawData[:person].occupation'),
        dob: get(profile, 'personDetail.dob'),
        country: get(profile, 'rawData[:person][:address].country'),
        placeOfBirth: get(profile, 'rawData[:person][:address].country'),
        debitCardPostcode: get(profile, 'rawData[:person][:address].postcode'),
        debitCardCountry: get(profile, 'rawData[:person][:address].country'),
        debitCardState: get(profile, 'rawData[:person][:address].state'),
        debitCardSuburb: get(profile, 'rawData[:person][:address.]city'),
        debitCardline1: get(profile, 'rawData[:person][:address].line1'),
        embossName: `${get(profile, 'personDetail.firstname')}${get(profile, 'personDetail.middlename') ? ' ' + get(profile, 'personDetail.middlename')[0].toUpperCase() + ' ' : ' '}${get(profile, 'personDetail.lastname')}`,
        cardDeliveryAddress: get(profile, 'rawData[:person][:address].fullText'),
        passport: get(profile, 'license_category') ? get(profile, 'rawData[:person].frontPhotoOfPrimary') : ''
    };
};
export const processDraftData = (rawData) => {
    return rawData ? {
        ...rawData,
        // step: 0,
        // status:'returned',
        // cardType:'Gold Metal',
        cardholder: `${get(rawData, 'firstName')} ${get(rawData, 'lastName')}`,
        isdCode: get(rawData, 'phoneCode'),
        telephone: get(rawData, 'phoneNo'),
        placeOfBirth: get(rawData, 'birthPlace'),
        debitCardSuburb: get(rawData, 'city'),
        debitCardline1: get(rawData, 'village'),
        debitCardState: get(rawData, 'province'),
        debitCardPostcode: get(rawData, 'postcode'),
        debitCardCountry: get(rawData, 'birthCountry'),
        selfieHoldingPassport: get(rawData, 'selfiePassport'),
        passportExpiryDate: get(rawData, 'passportExpiry'),
        passportIssueDate: get(rawData, 'passportIssue'),
        embossName: get(rawData, 'embossname'),
        selfieHoldingCard: get(rawData, 'selfieImg'),
        country: get(rawData, 'passportNumber')?.split(' ')[0],
        passportNumber: get(rawData, 'passportNumber')?.split(' ')[1],
    } : {};
};

export const processCardData = (rawData) => {
    return rawData.length === 0 ? {} : {
        currency: 'USD',
        balance: currencyFormatorWithDecimal(get(rawData[0], 'balance') ? get(rawData[0], 'balance') : 0, '', 2, true),
        cardNumber: get(rawData[0], 'cardNumber') || '**** **** **** ****',
        cardExpiry: '--/--',
        type: get(rawData[0], 'cardType') || 'Black Plastic',
        status: get(rawData[0], 'status') || 'Activated',
        firstName: get(rawData[0], 'embossName')?.split(' ')[0] || 'Name',
        lastName: get(rawData[0], 'embossName')?.split(' ').length === 2 ? get(rawData[0], 'embossName')?.split(' ')[1] : get(rawData[0], 'embossName')?.split(' ')[2] || 'Here',
    };
};