import { get } from "lodash"

export const processBlockchain = (rawData)=>{
    return {
        rawData,
        id: get(rawData, 'id'),
        network:
            get(rawData, 'network') === 'ETH'
                ? 'Ethereum'
                : get(rawData, 'network'),
        cryptoCurrency: get(rawData, 'cryptoCurrency'),
        contractAddress: get(rawData, 'contractAddress'),
        platformWalletAddress: get(rawData, 'platformWalletAddress'),
        enable: Boolean(get(rawData, 'enable')),
        createdAt: get(rawData, 'createdAt'),
        name: get(rawData, ':plugin.displayName'),
        operatorWallet: get(rawData, ':hdwalletConfig.operatorWallet'),
        opServiceFee: get(rawData, ':hdwalletConfig.serviceFee'),
        minimalDeposit: get(rawData, ':hdwalletConfig.minimalDeposit'),
        hdWalletConfigId: get(rawData, 'hdwalletConfig'),
    };
}