import {get, isObject, startsWith} from 'lodash';

const HAS_PENDING_REQUEST = 'There is already a pending request, please do not submit it again.';
const codeMatcher = (code) => {
    switch (code) {
        case 'E_TRADE_CUT_OFF':
            return 'E_TRADE_CUT_OFF';
        case 'E_DUPLICATE':
            return 'E_DUPLICATE';
        case 'E_BAD_REQUEST':
            return 'E_BAD_REQUEST';
        case 'E_DUPLICATE':
            return 'E_DUPLICATE';
    }
};

export const getErrorMessage = (error) => {
    const code = get(error, 'code') || get(error, 'response.body.error.code');
    const errorMessage = code === 'HAS_PENDING_REQUEST' ? HAS_PENDING_REQUEST :
        get(error, 'response.body.error.message') ||
        (isObject(get(error, 'response.body.error'))
            ? false
            : get(error, 'response.body.error')) ||
        get(error, 'message');
    if (code && !errorMessage) {
        return codeMatcher('E_TRADE_CUT_OFF');
    }
    if(startsWith(errorMessage, 'Validation failed')){
        return 'Wrong verification code'
    }
    return errorMessage;
};
