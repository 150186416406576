import {get,isNull} from 'lodash';
import {processUserProfile} from './userProfile';
import {formatDateTimes, formatDate, currencyFormatorWithDecimal} from 'utils/formator';

const isJSON = (obj) => {
    try{
        JSON.parse(obj)
        return true
    }catch(e){
        return false
    }
}

export const processRequestList = (rawData) => {
    let type = get(rawData, 'type');
    let subType = get(rawData, 'subType');
    if (type === 'Account') {
        if (subType === 'Withdrawal') {
            type = 'withdrawalAccount';
        } else if (subType === 'application' || subType === 'MT4' || subType === 'MT5'||subType === 'MT4s2') {
            type = 'account';
        }
    } else if (type === 'Password') {
        type = 'accountPasswordChange';
    } else if (type === 'Leverage') {
        type = 'accountLeverageChange';
    } else if (type === 'IB') {
        type = 'ibApplication';
    } else if (type === 'Transfer') {
        type = 'transfer';
    } else if (type === 'Withdrawal') {
        type = 'withdrawal';
    } else if (type === 'Deposit') {
        type = 'deposit';
    } else if (type === 'General') {
        type = 'general';
    } else if (type === 'KYC/KYB') {
        type = 'kyc';
    } else if (type === 'Wallet') {
        if(subType === 'Withdrawal') type = 'withdrawalWallet'
        if(subType === 'Exchange') type = 'exchangeWithdrawal'
    }

    const params = get(rawData, 'params')? JSON.parse(get(rawData, 'params' || '{}')): null

    let status = get(rawData, 'status')
    if(type === 'Account' && subType === 'DebitCard'){
        if(get(rawData, 'status') === 'pending'){
            console.log(params)
            if(params.step === 4) {
                status = 'In review'
            }
            if(params.step === 5) {
                status = 'Card mailed'
            }
        }
    }
    console.log(status, get(rawData, 'id'), type, subType)
    return {
        ...rawData,
        rawData,
        type,
        rate: get(rawData, ':transaction.rate')|| (isJSON(params)?get(rawData, 'params.rate'):1),
        paymentGateway: !get(rawData, ':transaction') && (get(rawData, 'subType') === 'Support' || get(rawData, ':request.subType') === 'Support') &&
        (get(rawData, 'processingMethod') === 'Support' || get(rawData, ':request.processingMethod') === 'Support')
            ? 'Customer Support' : get(rawData, ':transaction.:paymentGateway.displayName')
            || get(rawData, ':request.:transaction.:paymentGateway.displayName'),
        proposed: JSON.parse(get(rawData, 'params') || '{}')?.value,
        clientDetail: processUserProfile(get(rawData, ':user')),
        lastUpdateOn: formatDateTimes(
            get(rawData, 'updatedAt') || get(rawData, 'createdAt'),
        ),
        method: get(rawData, ':transaction.method'),
        subMethod: get(rawData, ':transaction.subMethod'),
        requestOn: formatDateTimes(get(rawData, 'createdAt')),
        params: isJSON(params)? JSON.parse(params): params,
        approveBy: get(rawData, ':approveBy') || get(rawData, ':approval.:approveBy'),
        assignBy: get(rawData, ':assignBy') || get(rawData, ':task.:assignBy'),
        processBy: get(rawData, ':processBy') || get(rawData, ':task.:processBy'),
        approvalId: get(rawData, ':approval.id'),
        taskId: get(rawData, ':task.id'),
        withdrawalAccount: get(rawData, ':transaction.:withdrawalAccount'),
        adminVerify: isNull(get(rawData, ':transaction.:withdrawalAccount.adminVerify')) ? null :
            get(rawData, ':transaction.:withdrawalAccount.adminVerify') ? 'valid' : 'invalid',
        systemVerify: isNull(get(rawData, ':transaction.:withdrawalAccount.systemVerify'))?null :get(rawData, ':transaction.:withdrawalAccount.systemVerify')? 'valid' : 'invalid',
        isVerify: Boolean(get(rawData, ':transaction.:withdrawalAccount.adminVerify')) ||
            (isNull(get(rawData, ':transaction.:withdrawalAccount.adminVerify')) &&
                Boolean(get(rawData, ':transaction.:withdrawalAccount.systemVerify'))),
        canPay: Boolean(get(rawData, ':transaction.:withdrawalAccount.adminVerify')) || get(rawData, ':transaction.:withdrawalAccount.adminVerify') === null,
        user: get(rawData, ':user'),
        beneficiaryInfo: get(rawData, 'beneficiaryInfo'),
        withdrawalAccountId: get(rawData, ':transaction.:withdrawalAccount.id'),
        routeType: get(rawData, ':transaction.:withdrawalAccount.routeType'),
        withdrawalAccountType: get(rawData, ':transaction.:withdrawalAccount.type'),
        isCryptoAccountSignValid: get(rawData, 'isCryptoAccountSignValid'),
        status
    };
};
export const processRequestDetail = (rawData) => {
    return {
        ...rawData,
        rawData,
        lastUpdatedAt: formatDateTimes(
            get(rawData, 'updatedAt') || get(rawData, 'createdAt'),
        ),
    };
};
export const processTasksList = (rawData) => {
    return {
        ...rawData,
        rawData,
        clientDetail: processUserProfile(get(rawData, ':user')),
    };
};


export const processApprovalTransaction = (rawData, user, withdrawalAccount, request) => {
    return {
        ...rawData,
        clientDetail: processUserProfile(user),
        lastUpdateOn: formatDate(get(rawData, 'updatedAt')),
        requestOn: formatDate(get(rawData, 'createdAt')),
        crmTicket: get(rawData, 'crmTicket'),
        ticket: get(rawData, 'ticket'),
        method: get(rawData, 'method'),
        subMethod: get(rawData, 'subMethod'),
        paymentGateway: get(rawData, ':paymentGateway.displayName'),
        attachment: get(rawData, 'attachment'),
        fromAccount: get(rawData, 'fromAccountName') ?
            `${get(rawData, 'fromAccountSource') || get(rawData, 'source')}-${get(rawData, 'fromAccountName',
            )}` : '',
        from_amount: get(rawData, 'fromAmount') ? currencyFormatorWithDecimal(
            -Math.abs(get(rawData, 'fromAmount')),
            get(rawData, 'fromCurrency') || get(rawData, 'fromCryptoCurrency'),
            get(rawData, 'fromCryptoCurrency') ? 8 : 2,
            true,
        ) : '',
        toAccount: get(rawData, 'type') === 'Withdrawal' ?
            get(rawData, ':withdrawalAccount.displayName') : get(rawData, 'toAccountName')
                ? `${get(rawData, 'toAccountSource') || get(rawData, 'source')}-${get(rawData, 'toAccountName')}`
                : '',
        to_amount: get(rawData, 'toAmount') ? currencyFormatorWithDecimal(
            get(rawData, 'toAmount'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') || get(rawData, 'toCurrency'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') ? 8 : 2,
            true,
        ) : '',
        toCurrency: get(withdrawalAccount, 'cryptoCurrency') || get(withdrawalAccount, 'currency'),
        fromAmount: get(rawData, 'fromAmount'),
        toAmount: get(rawData, 'toAmount'),
        paymentReference: JSON.parse(get(request, 'params') || '{}')?.paymentReference,
        params: JSON.parse(get(request, 'params') || '{}'),
        request,
        rawData: rawData,
    };
};
