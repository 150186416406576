import {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {get, reverse, capitalize} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processRequestList} from 'data/tasks';
import {
    formatDate,
    formatDateTimes,
    formatMonthDataAndYear,
    currencyFormatorWithDecimal,
    formatDateTimes2
} from 'utils/formator';
import {processSalePerformance} from '../../data/risk';
import Excel from 'exceljs';

export const useRequestList = (options = {}) => {
    const {
        type,
        username,
        status,
        ticket,
        subType,
        processingMethod,
        paymentGateway,
        source = null,
        startDate,
        endDate,
        subMethod,
        subMethodIsNull,
    } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'request-list',
            type,
            username,
            status,
            ticket,
            subType,
            processingMethod,
            paymentGateway,
            source,
            startDate,
            endDate,
            subMethod,
            subMethodIsNull,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            let [
                _,
                _type,
                _username,
                _status,
                _ticket,
                _subType,
                _processingMethod,
                _paymentGateway,
                _source,
                _startDate,
                _endDate,
                _subMethod,
                _subMethodIsNull,
            ] = _key;

            let query = {
                type: _type === 'Summary' ? undefined : _type,
                username: _username,
                status: _status,
                ticket: _ticket,
                subType: _subType,
                processingMethod: _processingMethod,
                paymentGateway: _paymentGateway,
                subMethod: _subMethod,
                subMethodIsNull: _subMethodIsNull,
                source: _source,
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };

            if (_type === 'Summary') {
                query.order = 'asc';
            }

            const {result} = await adminClient.get(
                ['workflow', 'client-request'],
                cleanObjectNilValue(query),
            );
            // const {result} = {
            //     result: {
            //         items: mockRequest,
            //         totalItems: 1,
            //     },
            // };
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processRequestList),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
        config: {
            keepPreviousData: false
        }
    });
    return swr;
};
export const useRequestDetail = (options = {}) => {
    const {requestId, request = true} = options;
    const swr = useSWRTemplate({
        key: requestId && request ? ['client-request', requestId] : null,
        request: async ([_key, _requestId]) => {
            const {result} = await adminClient.get([
                'workflow',
                'client-request',
                _requestId,
            ]);
            return processRequestList(result);
        },
        defaultValue: {},
    });
    return swr;
};
export const useRequestSummary = (options = {}) => {
    const {source = 'All'} = options;
    return useSWRTemplate({
        key: ['request-summary', source],
        request: async ([_key, _source]) => {
            const {result} = await adminClient.get(
                ['workflow', 'dashboard'],
                cleanObjectNilValue({
                    source: _source,
                }),
            );
            return {
                'task-summary':
                    get(result, 'task.deposit') +
                    get(result, 'task.leverage') +
                    get(result, 'task.password') +
                    get(result, 'task.transfer') +
                    get(result, 'task.withdrawal') +
                    get(result, 'task.general'),
                'task-deposit': get(result, 'task.deposit'),
                'task-leverage': get(result, 'task.leverage'),
                'task-password': get(result, 'task.password'),
                'task-transfer': get(result, 'task.transfer'),
                'task-withdrawal': get(result, 'task.withdrawal'),
                'task-general': get(result, 'task.general'),
                'approval-summary':
                    get(result, 'approval.account') +
                    get(result, 'approval.ib') +
                    get(result, 'approval.kyc') +
                    get(result, 'approval.withdrawal') +
                    get(result, 'approval.withdrawalAccount') +
                    get(result, 'approval.withdrawalWallet'),
                'approval-account': get(result, 'approval.account'),
                'approval-IB': get(result, 'approval.ib'),
                'approval-kyc': get(result, 'approval.kyc'),
                'approval-withdrawal': get(result, 'approval.withdrawal'),
                'approval-withdrawalAccount': get(
                    result,
                    'approval.withdrawalAccount',
                ),
                'approval-wallet': get(result, 'approval.withdrawalWallet'),
                'approval-exchangeWithdrawal': get(
                    result,
                    'approval.exchangeWithdrawal',
                ),
                'auto-summary':
                    get(result, 'autoRequest.deposit') +
                    get(result, 'autoRequest.leverage') +
                    get(result, 'autoRequest.password') +
                    get(result, 'autoRequest.transfer') +
                    get(result, 'autoRequest.withdrawal') +
                    get(result, 'autoRequest.general'),
                'auto-deposit': get(result, 'autoRequest.deposit'),
                'auto-leverage': get(result, 'autoRequest.leverage'),
                'auto-password': get(result, 'autoRequest.password'),
                'auto-transfer': get(result, 'autoRequest.transfer'),
                'auto-withdrawal': get(result, 'autoRequest.withdrawal'),
                'auto-general': get(result, 'autoRequest.general'),
                'auto-account': get(result, 'autoRequest.account'),
            };
        },
        defaultValue: {},
    });
};

export const useApprovalList = (options = {}) => {
    const {type, username, status, ticket, subType, approvalId, requestId} =
        options;
    const swr = useSWRPaginationTemplate({
        key: [
            'approval-list',
            type,
            username,
            status,
            ticket,
            subType,
            approvalId,
            requestId,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            let [
                _,
                _type,
                _username,
                _status,
                _ticket,
                _subType,
                _approvalId,
                _requestId,
            ] = _key;
            if (_type === 'Summary') {
                _type = undefined;
            }
            const query = {
                type: _type,
                username: _username,
                status: _status,
                ticket: _ticket,
                requestId: _requestId,
                subType: _subType,
                approvalId: _approvalId === '' ? null : _approvalId,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['workflow', 'approval'],
                cleanObjectNilValue(query),
            );
            // const {result} = {
            //     result: {
            //         items: mockRequest,
            //         totalItems: 1,
            //     },
            // };
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            ...processRequestList(get(item, ':request')),
                            approvalId: get(item, 'id'),
                            lastUpdateOn: formatDateTimes(
                                get(item, 'updatedAt') ||
                                    get(item, 'createdAt'),
                            ),
                            status: get(item, 'status'),
                            requestOn: formatDateTimes(get(item, 'createdAt')),
                        };
                    }),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useTaskList = (options = {}) => {
    const {
        type,
        username,
        status,
        ticket,
        subType,
        taskId,
        isAssign,
        paymentGateway,
        source,
    } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'task-list',
            type,
            username,
            status,
            ticket,
            subType,
            taskId,
            isAssign,
            paymentGateway,
            source,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            let [
                _,
                _type,
                _username,
                _status,
                _ticket,
                _subType,
                _taskId,
                _isAssign,
                _paymentGateway,
                _source,
            ] = _key;
            if (_type === 'Summary') {
                _type = undefined;
            }
            const query = {
                type: _type,
                username: _username,
                status: _status,
                ticket: _ticket,
                subType: _subType,
                taskId: _taskId === '' ? null : _taskId,
                isAssign: _isAssign,
                paymentGateway: _paymentGateway,
                source: _source,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['workflow', 'task'],
                cleanObjectNilValue(query),
            );
            // const {result} = {
            //     result: {
            //         items: mockRequest,
            //         totalItems: 1,
            //     },
            // };
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            ...processRequestList(get(item, ':request')),
                            taskId: get(item, 'id'),
                            status: get(item, 'status'),
                        };
                    }),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useGeneralList = (options = {}) => {
    const {type, status, taskId, username, subType} = options;
    const swr = useSWRPaginationTemplate({
        key: ['general-list', type, status, taskId, username, subType],
        request: async (_key, _pageIndex, _pageSize) => {
            let [_, _type, _status, _taskId, _username, _subType] = _key;
            const query = {
                type: _type,
                status: _status,
                taskId: _taskId,
                username: _username,
                subType: _subType,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['workflow', 'task'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            ...processRequestList(get(item, ':request')),
                        };
                    }),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const processRequest = async (taskId, status, declineReason) => {
    const {result} = await adminClient.post(
        ['workflow', 'task'],
        cleanObjectNilValue({taskId, status, declineReason}),
    );
    return result;
};

export const processDepositTask = async (
    taskId,
    status,
    reason,
    isDeposit,
    realAmount,
) => {
    const {result} = await adminClient.post(
        ['workflow', 'trade', 'deposit'],
        cleanObjectNilValue({
            taskId,
            status,
            reason,
            isDeposit,
            amount: realAmount,
        }),
    );
    return result;
};
export const processFinanceDeposit = async (taskId, realAmount) => {
    const {result} = await adminClient.put(
        ['finance', 'batch-deposit', taskId],
        cleanObjectNilValue({amount: realAmount}),
    );
    return result;
};
export const processWithdrawalTask = async (
    taskId,
    status,
    reason,
    realAmount,
    realRate,
    paymentReference,
    processMethod = null,
    realCurrency
) => {
    const {result} = await adminClient.post(
        ['workflow', 'trade', 'process-withdrawal'],
        cleanObjectNilValue({
            taskId,
            status,
            reason,
            realAmount,
            realRate,
            paymentReference,
            processMethod,
            realCurrency
        }),
    );
    return result;
};

export const processStockPassword = async (taskId, data) => {
    const {result} = await adminClient.post(
        ['workflow', 'reset-stock-password'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const approvalWithdrawal = async (approvalId, status, reason) => {
    const {result} = await adminClient.post(
        ['workflow', 'trade', 'approval-withdrawal'],
        cleanObjectNilValue({approvalId, status, reason}),
    );
    return result;
};

export const approvalFxAccount = async (approvalId, status, reason) => {
    const {result} = await adminClient.post(
        ['workflow', 'mt-account'],
        cleanObjectNilValue({approvalId, status, reason}),
    );
    return result;
};

export const approvalWallet = async (approvalId, status, reason) => {
    const {result} = await adminClient.post(
        ['workflow', 'withdrawal-wallet'],
        cleanObjectNilValue({approvalId, status, reason}),
    );
    return result;
};

export const approvalCard = async (requestId, status, reason) => {
    const {result} = await adminClient.put(
        ['dtps', 'approve', requestId],
        cleanObjectNilValue({status, reason}),
    );
    return result;
};
export const clientDetailApprovalCard = async (status, reason, user) => {
    const {result} = await adminClient.post(
        ['dtps', 'approve'],
        cleanObjectNilValue({status, reason, user}),
    );
    return result;
};
export const approvalCardTopup = async (requestId, status, reason) => {
    const {result} = await adminClient.put(
        ['dtps', 'approve-topup', requestId],
        cleanObjectNilValue({status, reason}),
    );
    return result;
};

export const takeOverTasks = async (taskId) => {
    const {result} = await api().post(['workflow', 'take-over'], {taskId});
    return result;
};

export const reassignTasks = async (data) => {
    const {result} = await api().post(['workflow', 'reassign'], data);
    return result;
};

export const approvalRequest = async (
    approvalId,
    status,
    declineReason,
    type,
    level,
    upperIb,
    sale,
    ibCode,
    ibLevel,
) => {
    const {result} = await api().post(
        [
            'workflow',
            type === 'withdrawalAccount'
                ? 'withdrawal-account'
                : type === 'ibApplication'
                ? 'ib'
                : type === 'accountLeverageChange'
                ? 'fx/leverage'
                : 'approval',
        ],
        cleanObjectNilValue({
            approvalId,
            status,
            reason: declineReason,
            commissionRate: level ? Number(level) : null,
            targetNodeId: upperIb,
            saleId: sale,
            ibCode,
            ibLevel,
        }),
    );
    return result;
};

export const adminCreateRequest = async (data) => {
    const {result} = await api().post(
        ['workflow', 'client-request'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useTotalFXBalanceTrends = (options = {}) => {
    const {origin} = options;
    const swr = useSWRTemplate({
        key: ['TotalFXBalanceTrends', origin],
        request: async ([_, _origin]) => {
            const {result} = await api().get(
                ['dashboard', 'balance-chart'],
                cleanObjectNilValue({origin: _origin}),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useTotalFXBalanceDetails = (options = {}) => {
    const {origin, yearMonth} = options;
    const swr = useSWRTemplate({
        key: yearMonth?['TotalFXBalanceTrends', origin, yearMonth]:null,
        request: async ([_, _origin, _yearMonth]) => {
            const {result} = await api().get(
                ['dashboard', 'balance-chart'],
                cleanObjectNilValue({origin: _origin, yearMonth: _yearMonth}),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useTotalStockBalanceTrends = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['TotalFXBalanceTrends'],
        request: async ([_]) => {
            const {result} = await api().get(['stock', 'balance-chart']);
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useDepositWithdrawalNetTrends = (options = {}) => {
    const {origin} = options;
    const swr = useSWRTemplate({
        key: ['DepositWithdrawalNetTrends', origin],
        request: async ([_, _origin]) => {
            const {result} = await api().get(
                ['dashboard', 'funding-chart'],
                cleanObjectNilValue({origin: _origin}),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useDepositWithdrawalNetDetails = (options = {}) => {
    const {origin, yearMonth} = options;
    const swr = useSWRTemplate({
        key: yearMonth?['DepositWithdrawalNetTrends', origin, yearMonth]:yearMonth,
        request: async ([_, _origin, _yearMonth]) => {
            const {result} = await api().get(
                ['dashboard', 'funding-chart'],
                cleanObjectNilValue({origin: _origin, yearMonth: _yearMonth}),
            );
            return {
                ...result,
                data: get(result, 'data').map(item=>{
                    return {
                        ...item,
                        net: Number(get(item, 'deposit')||0)+Number(get(item, 'withdrawal')||0)
                    }
                })
            }
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useCryptoGapsTrends = (options = {}) => {
    const {origin, year} = options;
    const swr = useSWRTemplate({
        key: ['useCryptoGapsTrends', year],
        request: async ([_, _year]) => {
            const {result} = await api().get(
                ['dashboard', 'fund-flow-report'],
                cleanObjectNilValue({year: _year}),
            );
            return {
                data: get(result, 'data').map((item) => {
                    return {
                        ...item,
                        total: Number(item.total),
                    };
                }).sort((val, next)=>Number(next.gapLevel)-Number(val.gapLevel)),
                years: get(result, 'years'),
                summary: get(result, 'summary')
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        level0: swr.data?.data?.find((item) => {
            return Number(get(item, 'gapLevel')) === 0;
        }),
        level1: swr.data?.data?.find((item) => {
            return Number(get(item, 'gapLevel')) === 1;
        }),
        level2: swr.data?.data?.find((item) => {
            return Number(get(item, 'gapLevel')) === 2;
        }),
        level3: swr.data?.data?.find((item) => {
            return Number(get(item, 'gapLevel')) === 3;
        }),
        level4: swr.data?.data?.find((item) => {
            return Number(get(item, 'gapLevel')) === 4;
        }),
        totalClient: swr.data?.data?.find((item) => {
            return Number(get(item, 'total')) !== 0;
        })?.total,
        yearList: swr.data?.years?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
        summary: get(swr, 'data.summary')
    };
};

export const useCryptoGapsDetails = (options = {}) => {
    const {level, year} = options;
    const swr = useSWRPaginationTemplate({
        key: level ? ['useCryptoGapsDetails', level, year] : null,
        request: async ([_, _level, _year], _pageIndex, _pageSize) => {
            console.log(year)
            const query = {
                tradeType: 'crypto',
                year: _year,
                pageIndex: _pageIndex-1,
                pageSize: _pageSize
            }
            const {result} = await api().get(
                ['dashboard', 'fund-flow-report', _level - 1],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'data.items').map((item) => {
                        return {
                            ...item,
                            total: Number(item.total),
                            clientDetail: {
                                name: get(item, 'username', get(item, 'email')),
                                id: get(item, 'userId'),
                            },
                        };
                    }),
                    totalItems: get(result, 'data.totalItems')
                }
            }
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useForexTradeVolumeTrends = (options = {}) => {
    const {origin} = options;
    const swr = useSWRTemplate({
        key: ['ForexTradeVolumeTrends', origin],
        request: async ([_, _origin]) => {
            const {result} = await api().get(
                ['dashboard', 'closed-order-chart'],
                cleanObjectNilValue({origin: _origin}),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAdminTradesTrends = (options = {}) => {
    const {origin, type} = options;
    const swr = useSWRTemplate({
        key: ['AdminTradesTrends', origin, type],
        request: async ([_, _origin, _type]) => {
            const {result} = await api().get(
                ['dashboard', `${_type}-chart`],
                cleanObjectNilValue({
                    origin: _origin,
                }),
            );
            return {
                ...result,
                data: get(result, 'data').map((item) => {
                    return {
                        ...item,
                        amount:
                            type === 'deposit'
                                ? get(item, 'deposit')
                                : type === 'withdrawal'
                                ? get(item, 'withdrawal')
                                : get(item, 'commission'),
                    };
                }),
            };
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

export const useSalesReportTrends = (options = {}) => {
    const {year, origin} = options;
    const swr = useSWRTemplate({
        key: ['SalesReportTrends', year, origin],
        request: async ([_, _year, _origin]) => {
            const query = {
                year: _year,
                origin: _origin,
            };
            const {result} = await api().get(
                ['dashboard', 'sales-report-chart'],
                cleanObjectNilValue(query),
            );
            return {
                data: get(result, 'data').reverse(),
                years: get(result, 'years')
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        yearList: swr.data?.years?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useTotalClientByKycTrends = (options = {}) => {
    const {year, kycStatus, region} = options;
    const swr = useSWRTemplate({
        key: ['dashboard/client-trends', year, kycStatus, region],
        request: async ([_, _year, _kycStatus, _region]) => {
            const query = {
                year: _year,
                kycStatus: _kycStatus,
            };
            const {result} = await api().get(
                ['dashboard', 'client-trends'],
                cleanObjectNilValue(query),
            );
            return {
                ...result,
                data: get(result, 'data').map((item)=>processClient(item, _region)).reverse()
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        yearList: swr.data?.years?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

const processClient = (rowData, region) => {
    return {
        ...rowData,
        new_users: !region ? get(rowData, 'new_users') : region === 'US' ? get(rowData, 'user_us') : get(rowData, 'user_non_us')
    }
}

export const useMonthSalesReport = (options = {}) => {
    const {username, origin, month, request = true} = options;
    const swr = useSWRPaginationTemplate({
        key: request
            ? ['monthSalesReport-list', username, origin, month]
            : null,
        request: async (_key, _pageIndex, _pageSize) => {
            let [_, _username, _origin, _month] = _key;
            const query = {
                // username: _username,
                origin: _origin,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['dashboard', 'sales-report-chart', _month],
                cleanObjectNilValue(query),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: result.items.map(processSalePerformance),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useRequestPaymentGateway = () => {
    const swr = useSWRTemplate({
        key: ['request-payment-gateway'],
        request: async ([_]) => {
            const {result} = await api().get(['support', 'payment-gateway']);
            return result.map((item) => {
                return {
                    ...item,
                    value: get(item, 'id'),
                    label: get(item, 'displayName'),
                };
            });
        },
        defaultValue: [],
    });
    return swr;
};

export const supportDeposit = async (options = {}) => {
    const result = await api().post(
        ['support', 'deposit'],
        cleanObjectNilValue(options),
    );
    return result;
};

export const convertToTask = async (data) => {
    const result = await api().post(['workflow', 'task'], data);
    return result;
};

export const adminSupportDeposit = async (options = {}) => {
    const result = await api().post(
        ['workflow', 'trade', 'customer-deposit'],
        cleanObjectNilValue(options),
    );
    return result;
};

export const adminSupportTransfer = async (data) => {
    const {result} = await api().post(
        ['support', 'transfer'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const adminSupportWithdrawal = async (data) => {
    const {result} = await api().post(
        ['support', 'withdrawal'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const adminSupportApplyAccount = async (data) => {
    const {result} = await api().post(
        ['support', 'apply-account'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useFinanceRequests = (options = {}) => {
    const {ticket, client, method} = options;
    const swr = useSWRTemplate({
        key: ['finance-request-list', ticket, client, method],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _ticket, _client, _method] = _key;
            const query = {
                ticket: _ticket,
                username: _client,
                method: _method,
            };
            const {result} = await adminClient.get(
                ['finance', 'batch-deposit'],
                cleanObjectNilValue(query),
            );
            return result.map(processRequestList).filter((item) => {
                return get(item, 'subType') !== 'Support';
            });
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processRequestList),
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        processList: swr.data.filter((item) => {
            return (
                get(item, 'params.to.amount') &&
                get(item, 'subType') !== 'Support'
            );
        }),
    };
};

export const processFinanceDepositList = async (arr) => {
    const {result} = await adminClient.post(
        ['finance', 'batch-deposit'],
        cleanObjectNilValue({allPending: arr}),
    );
    return result;
};

export const verifyWithdrawalInfo_ = async (data) => {
    const {result} = await api().post(
        ['withdrawal-account', 'change-account-admin-verify'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const createBeneficiary_ = async (data) => {
    const {result} = await api().post(
        ['withdrawal-account', 'create-beneficiary'],
        cleanObjectNilValue(data),
    );
    return result;
};



export const DownloadApprovalFile = async (
    data,
    type = 'transaction',
    networks = [],
) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Transaction data');
    sheet.columns = [
        {header: 'Latest update', key: 'latestUpdate'},
        ...(type === 'withdrawal' ? [{header: 'Request', key: 'requestTicket'}] : []),
        {header: 'CRM ticket', key: 'crmTicket'},
        {header: 'Client', key: 'client'},
        {header: 'To', key: 'toFxAccount'},
        {header: 'Amount', key: 'toAmount'},
        ...(type === 'withdrawal' ||
        type === 'deposit' ||
        type === 'commission' ||
        type === 'transfer'
            ? [
                {header: 'Rate', key: 'rate'},
                {header: 'Fee', key: 'fee'},
            ]
            : []),
        ...(type === 'withdrawal' || type === 'transaction' || type === 'Stock transaction'
            ? [
                {header: 'Source', key: 'source'},
                {header: 'Email', key: 'email'},
                {header: 'Crypto network', key: 'chainType'},
                {header: 'Account number', key: 'accountNumber'},
                {header: 'Account holder name', key: 'accountHolderName'},
                {header: 'Withdrawal method', key: 'routeType'},
                {header: 'BIC/SWIFT', key: 'routeCode'},
            ]
            : []),
        {header: 'Status', key: 'status'},
        {header: 'Notes', key: 'comment'},
        ...(type === 'withdrawal'
            ? [{header: 'Payment reference', key: 'paymentReference'}]
            : []),
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'latestUpdate':
                    row.push(
                        get(grant, 'mtTicket')
                            ? get(grant, 'statusCompletedTimestamp') ||
                            get(grant, 'updatedAt')
                            : get(grant, 'updatedAt') ||
                            get(grant, 'createdAt'),
                    );
                    break;
                case 'requestTicket':
                    row.push(get(grant, 'request.ticket'));
                    break;
                case 'ticket':
                    row.push(get(grant, 'mtTicket'));
                    break;
                case 'crmTicket':
                    row.push(get(grant, 'ticket'));
                    break;
                case 'riskStatus':
                    row.push(get(grant, 'riskStatus'));
                    break;
                case 'type':
                    row.push(get(grant, 'type'));
                    break;
                case 'method':
                    row.push(
                        get(grant, 'subMethod')==='VA'?'CC Subaccount':
                        (get(grant, 'method') ||
                            get(grant, ':paymentGateway.displayName')),
                    );
                    break;
                case 'client':
                    row.push(get(grant, 'clientDetail.name'));
                    break;
                case 'fromAccount':
                    row.push(get(grant, 'fromAccount'));
                    break;
                case 'fromAmount':
                    row.push(
                        get(grant, 'type') === 'Deposit'
                            ? ''
                            : get(grant, 'from_amount'),
                    );
                    break;
                case 'toFxAccount':
                    row.push(get(grant, 'toAccount'));
                    break;
                case 'toAmount':
                    row.push(get(grant, 'to_amount'));
                    break;
                case 'rate':
                    row.push(`${Number(get(grant, 'rate')) || 1}:1`);
                    break;
                case 'fee':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'fee') || 0,
                            get(grant, 'fromCurrency') ||
                            get(grant, 'fromCryptoCurrency'),
                            get(grant, 'fromCryptoCurrency') ? 8 : 2,
                            true,
                        ),
                    );
                    break;

                case 'source':
                    row.push(get(grant, 'source') ? get(grant, 'source') : 'Forex');
                    break;
                case 'email':
                    row.push(
                        get(grant, 'clientDetail.email'),
                    );
                    break;
                case 'chainType':
                    const dic = networks.find(
                        (d) =>
                            d?.chain ===
                            get(grant, ':withdrawalAccount.chainType'),
                    );
                    row.push(get(dic, ':chain.name'));
                    break;
                case 'accountNumber':
                    row.push(get(grant, ':withdrawalAccount.accountNumber'));
                    break;
                case 'accountHolderName':
                    row.push(
                        get(grant, ':withdrawalAccount.accountHolderName'),
                    );
                    break;
                case 'routeType':
                    row.push(get(grant, ':withdrawalAccount.routeType'));
                    break;
                case 'routeCode':
                    const routeCode = get(grant, ':withdrawalAccount.routeCode')
                    const bankDetails = JSON.parse(get(grant, ':withdrawalAccount.bankDetails')||"{}")
                    const branchCode = get(bankDetails, 'branchCode')
                    const code = branchCode? `${routeCode}-${branchCode}`: routeCode
                    row.push(code);
                    break;

                case 'dob':
                    row.push(get(grant, ':withdrawalAccount.dob'));
                    break;
                case 'address':
                    row.push(get(grant, ':withdrawalAccount.:address.fullText'));
                    break;
                case 'mobile':
                    row.push(get(grant, ':user.mobile'));
                    break;
                case 'idNumber':
                    row.push(get(grant, ':withdrawalAccount.idNo'));
                    break;

                case 'status':
                    row.push(capitalize(get(grant, 'status')));
                    break;
                case 'comment':
                    row.push(get(grant, 'comment'));
                    break;
                case 'paymentReference':
                    row.push(get(grant, 'paymentReference'));
                    break;
            }
        }
        sheet.addRow(row);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Approval-${capitalize(type)}s-${formatDateTimes2(new Date())}.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};
