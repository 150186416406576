import React, {useState} from 'react';
import {Stack, Text, Heading, Button, HStack, Icon, Center, Box, Image, IconButton, Spinner, Card} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useColorModeValueKey} from 'hooks/useColors';
import {depositReceiptList} from './receipts/deposit';
import {HiOutlineDocument, HiOutlineDocumentText} from 'react-icons/hi';
import {withdrawalReceiptList} from './receipts/withdrawal';
import {transferReceiptList} from './receipts/transfer';
import {moneyMoveReceiptList} from './receipts/moneyMove';
import {commissionReceiptList} from './receipts/commission';
import {debitCardReceiptList} from './receipts/debitCard';
import {useAuth} from 'hooks/useAuth';
import {isImageExtension} from '../../utils/mime';
import { split, takeRight } from 'lodash';
import {stockProductReceiptList} from './receipts/stockProduct';

const receipts = {
    Deposit: depositReceiptList,
    Withdrawal: withdrawalReceiptList,
    Transfer: transferReceiptList,
    'Money move': moneyMoveReceiptList,
    Adjustment: moneyMoveReceiptList,
    CardFee: debitCardReceiptList,
    CardTopup: debitCardReceiptList,

    //stock
    stockProduct: stockProductReceiptList,
    Commission: commissionReceiptList,
};

export const DisplayBlock = (props) => {
    const {file} = props;
    const uri = file?.uri || file;
    const {t} = useTranslation('app');
    return (
        <Stack width={'100%'}>
            {isImageExtension(file?.path || uri) ? (
                <HStack onClick={() => window.open(uri, '_blank')}>
                    <Box>
                        <Image
                            src={uri}
                            objectFit={'contain'}
                            boxSize={'50px'}
                        />
                    </Box>
                </HStack>
            ) : (
                <HStack
                    py={1}
                    alignItems={'center'}
                    justifyContent="space-between"
                    width={'100%'}
                >
                    <Box
                        cursor={'pointer'}
                        flex={1}
                        onClick={() => window.open(uri, '_blank')}
                    >
                        <HStack alignItems={'center'}>
                            <Icon as={HiOutlineDocumentText} boxSize={5} mr={1} />
                            <Text noOfLines={1}>{file?.path || uri}</Text>
                        </HStack>
                    </Box>
                </HStack>
            )}
        </Stack>
    );
};

const ReceiptLine = (props) => {
    const {label, value,component, file} = props;
    const grayColor = useColorModeValueKey('gray');

    return (
        <Stack spacing={0}>
            {label ? (
                <Text color={grayColor} fontSize={'xs'} maxW={'100%'}>
                    {label}
                </Text>
            ) : null}
            {file ? (
                // <Stack
                //     onClick={() => window.open(value, '_blank')}
                //     py={3}
                //     // px={3}
                //     alignItems={'center'}
                //     justifyContent="space-between"
                // >
                //     <HStack space={2}>
                //         <Icon as={HiOutlineDocumentText} boxSize={4} />
                //         <Text isTruncated flex={1}>
                //             {value}
                //         </Text>
                //     </HStack>
                // </Stack>
                <DisplayBlock file={value}/>
            ) : value ? (
                <Text maxW="100%">{value}</Text>
            ) :component?component: null}
        </Stack>
    );
};

const Receipt = (props) => {
    const {type = 'Deposit', customReceiptsGetter, data, noHeading, ...other} = props;
    const {t} = useTranslation('app');
    const getter = customReceiptsGetter || receipts[type] || (() => []);
    // console.log(data);
    const {isUser} = useAuth();
    const list = getter({i18n: t, data, isClientSide: isUser});
    return (
        <Stack {...other} spacing={4}>
            {!noHeading && (
                <Text fontWeight={'500'} fontSize={'lg'}>
                    {t('receipt')}
                </Text>
            )}
            <Stack>
                {list.map((item) => (
                    <ReceiptLine key={item.label} {...item} />
                ))}
            </Stack>
        </Stack>
    );
};

export default Receipt;
