import { get } from "lodash";

export const processThridPartyPaymentGateway = (rawData) => {
    return {
        rawData: rawData,
        id: get(rawData, 'id'),
        name: get(rawData, 'displayName'),
        displayName: get(rawData, 'displayName'),
        description: get(rawData, 'desc'),
        parameters: JSON.parse(get(rawData, 'parameters') || '{}'),
        authParams: JSON.parse(get(rawData, 'authParams') || '{}'),
        parameters: JSON.parse(get(rawData, 'callbackParams') || '{}'),
        requestParams: JSON.parse(get(rawData, 'requestParams') || '{}'),
        serviceFee: Number(get(rawData, 'serviceFee')),
        enable: Boolean(get(rawData, 'enable')),
        status: get(rawData, 'status'),
        speedDesc: get(rawData, 'speedDesc'),
    };
};
